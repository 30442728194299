import React from "react"
import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"
import apace_merchants from "../../../assets/new_images/apace_merchants.mp4"
import thumbnail from "../../../assets/new_images/apace_merchants_thumb.jpg"

const Header = () => {
  const { ref, inView } = useInView()

  return (
    <div className="w-full">
      <div className="container mx-auto sm:w-full pb-[110px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <motion.div
            initial={inView === false ? { x: -30, opacity: 0 } : {}}
            animate={inView === true ? { x: 0, opacity: 1 } : {}}
            transition={{ bounce: false, type: "spring", stiffness: 30 }}
          >
            <div className="flex flex-col self-center md:pt-[150px]" ref={ref}>
              <h1
                style={{ fontFamily: "Cabin", marginLeft: -5 }}
                className="text-[56px] leading-[60px]  md:text-[48px] md:leading-[56px] lg:text-[52px] lg:leading-[52px] 2xl:text-[78px] 2xl:leading-[78px] font-semibold Inter mb-[30px] md:mb-[45] lg:mb-[40px] pt-6 md:pt-0"
              >
                Bolster sales. <br />
                Build loyalty.
              </h1>

              <h2 className="text-[22px] leading-[32px] md:text-[28px] md:leading-[38px] 2xl:text-[29px] 2xl:leading-[43px] text-dark font-medium mb-[40px]  2xl:mr-[200px] md:mr-[40px]">
                <span className="md:text-[33px] text-[23.8px] mid-size-style mb-[10px]">
                  <strong>
                    Offer real-time instant refund{" "}
                    <span style={{ wordSpacing: 4 }}>
                      payments to your customers
                    </span>
                  </strong>
                </span>
                <video
                  className="merchant_video"
                  controls
                  loop={false}
                  poster={thumbnail}
                >
                  <source src={apace_merchants} type="video/mp4" />
                </video>
              </h2>
              <div className="flex justify-between md:justify-start">
                <a
                  href="https://merchant.apacerefunds.com/auth/signup"
                  target="_blank"
                  className="bg-primary flex justify-center items-center text-white text-base md:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl min-h-[50px] md:min-h-[54px] px-[35px] mr-[20px]"
                >
                  Get started
                </a>
                <a
                  href="#contact"
                  className="bg-[#e2ebf7] flex justify-center items-center text-primary md:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl min-h-[50px] md:min-h-[54px] px-[20px]"
                >
                  Request a demo
                </a>
              </div>
            </div>
          </motion.div>
          <div className="hidden md:block bg_header">
            <div className="flex w-full h-full items-center justify-center">
              <StaticImage
                src="../../../images/png/mobile_refunds_01.png"
                alt="Apace phone"
                className="md:pt-[120px] w-[100%] xl:w-[55%] mx-auto transition-all duration-600 transform hover:-translate-y-3"
                style={{
                  bottom: "-115px",
                  left: "-61px",
                  position: "relative",
                }}
                objectFit="cover"
              />
            </div>
            <div className="text-center mt-[-40px]">
              <a
                href="/brochure-download"
                className="text-center text-sm md:text-base text-[#007eff] underline"
              >
                Download our PDF brochure
              </a>
            </div>
          </div>
          <div className="block md:hidden">
            <motion.div
              initial={inView === false ? { x: -30, opacity: 0 } : {}}
              animate={inView === true ? { x: 0, opacity: 1 } : {}}
              transition={{ bounce: false, type: "spring", stiffness: 30 }}
            >
              <div className="text-center">
                <a
                  href="/brochure-download"
                  className="text-center text-sm md:text-base text-[#007eff] underline"
                >
                  Download our PDF brochure
                </a>
              </div>
            </motion.div>
            <div className="flex justify-center mt-[50px]">
              <StaticImage
                src="../../../images/png/mobile_refunds_mobile_01.png"
                alt="Apace phone"
                style={{
                  width: "60%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
