import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const RevenueShare = () => {
  return (
    <div
      className="max-w-[100%] w-[100%] md:mt-[-220px] md:pt-[230px] lg:pb-[90px] xl:pt-[440px] pt-[110px] pb-[330px] lg:mb-[100px] mb-[50px]"
      style={{
        "background-color": "#F4F7FD",
        "z-index": 2,
        position: "relative",
      }}
      id="reimbursement"
    >
      <div className="container">
        <h1 className="text-[30px] lg:text-[36px] lg:leading-[44px] 2xl:text-[42px] font-normal">
          Be reimbursed by Apace!
        </h1>
        <p className="text-[19px] lg:text-[22px] leading-[150%] 2xl:text-[30px] font-normal mt-[40px] lg:max-w-[40%]">
          By enabling the <b>Return Reimbursement Program (RRP)</b> option with
          Apace you can replenish up to 2% of every return cost.
        </p>
        <p className="text-[16px] lg:text-[22px] text-[#515151] leading-[150%] font-normal mt-[40px] lg:max-w-[40%]">
          Customers who opt-in to Apace instant refunds, will generate a
          reimbursement payment to the retailer associated with that specific
          return.
        </p>
        <a
          href="https://merchant.apacerefunds.com/auth/signup"
          target="_blank"
          className="mt-[40px] bg-[#3D87F5] inline-flex justify-center items-center text-white text-base md:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl w-auto min-h-[50px] px-[40px]"
        >
          Get started
        </a>
        <StaticImage
          src="../../../images/png/cost recoup.png"
          alt="Revenue share with Apace"
          style={{
            float: "right",
            "-webkit-filter": "drop-shadow(0 0 15px rgba(0,0,0,0.1))",
            filter: "drop-shadow(0 0 15px rgba(0,0,0,0.1))",
          }}
          className="lg:w-[58%] w-[100%] bottom-[-80px] lg:bottom-[110px]"
        />
      </div>
    </div>
  )
}

export default RevenueShare
