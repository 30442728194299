import React from "react"
import checkIcon from "../../../images/check.svg"
const Partnership = () => {
  return (
    <div
      className="w-full bg-[#F5F8FD]"
      id="partner-with-apace"
      style={{
        position: "relative",
        "z-index": "1",
      }}
    >
      <div className="max-w-[1664px] bg-[#004990] md:rounded-r-[40px] py-[110px]">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h1 className="text-[#FEFEFE] text-[32px] leading-[44px] md:text-[40px] md:leading-[58px] lg:text-[50px] lg:leading-[68px]  font-medium sm:max-w-[404px] mb-10 md:mb-0">
                We work in close partnership with the retailer.
              </h1>
            </div>
            <div>
              <div className="mb-10 md:pl-10">
                <Check>Easy non-intrusive system integration</Check>
                <Check>Negotiable invoice terms</Check>
                <Check>Detailed transaction info provided</Check>
                <Check>Full retailer portal included</Check>
                <Check>Network transaction ID provided for each refund</Check>
              </div>
              <div>
                <a
                  href="/"
                  className="bg-white italic flex justify-center items-center text-primary text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl max-w-[180px] min-h-[54px]"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partnership

const Check = ({ children }) => {
  return (
    <div className="flex gap-4 mb-4">
      <span>
        <img src={checkIcon} alt="" />
      </span>
      <span className="text-xl font-normal italic text-[#FEFEFE] flex self-center">
        {children}
      </span>
    </div>
  )
}
