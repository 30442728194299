import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const SimpleIntegrations = () => {
  return (
    <div className="w-full bg-[#F4F7FD]" id="integration">
      <div className="container py-[60px] md:py-[60px] md:pt-[110px]">
        <div className="border-b border-b-[#D4D4D4] pb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <StaticImage
                src="../../../images/png/mobile_refunds_02.png"
                alt="Apace Refunds - easy integration"
              />
            </div>
            <div className="sm:max-w-[600px]">
              <h1 className=" text-[32px] leading-[45px] md:text-[38px] md:leading-[58px] lg:text-[40px] lg:leading-[60px] 2xl:text-[59px] 2xl:leading-[80px]  text-[#004990] text-center md:text-left font-bold  mb-[50px]">
                Simple integration.
                <br />
                Satisfied customers.
              </h1>
              <p className=" text-lg md:text-xl 2xl:text-[24px] 2xl:leading-[32px] text-center md:text-left italic  text-[#004990] font-normal  mb-[50px]">
                Apace offers a simple plug-and-play integration with a detailed
                developers documentation and full sandbox environment.
              </p>
              <h4 className="text-base md:text-xl 2xl:text-2xl 2xl:leading-[34px] font-bold text-black text-center md:text-left">
                Ready to test it?
              </h4>
              <h3 className="text-base md:text-xl 2xl:text-2xl 2xl:leading-[34px] font-normal text-black text-center md:text-left italic mb-[40px]">
                Sign up for the Sandbox Merchant Portal
              </h3>
              <div className="flex justify-center w-full md:justify-start mb-5">
                <a
                  href="https://merchant.apacerefunds.com/auth/signup"
                  className="bg-[#004990] flex justify-center items-center text-white text-base md:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl min-w-[180px] max-w-[180px] min-h-[50px] md:min-h-[54px]"
                  target="_blank"
                >
                  Get started
                </a>
              </div>
              <div className="md:text-left text-center">
                <a
                  href="https://docs.apacerefunds.com/reference/get-started-with-the-apace-external-module"
                  className="text-center text-sm md:text-base text-[#181818] underline"
                  target="_blank"
                >
                  View our developers documentation
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[800px] mx-auto">
          <div className=" max-w-[160px] mx-auto pt-10 mb-8"></div>
          <h1 className="text-center text-black italic font-normal text-xl md:text-[24px] 2xl:text-[43px] mb-14">
            Platform integration
          </h1>
          <div className="flex align-center justify-between mb-8 gap-x-4 md:gap-x-10">
            <div className="flex mb-2 cursor-pointer">
              <a
                target="_blank"
                href="https://marketplace.magento.com/apace-refunds-magento2-apace-refund-payment.html"
              >
                <StaticImage
                  src="../../../images/png/Magento-small.png"
                  alt="Magento logo"
                  height={70}
                  className="ecommerce-logo"
                />
              </a>
            </div>
            <div className="flex mb-2 cursor-pointer">
              <a
                target="_blank"
                href="https://docs.apacerefunds.com/reference/bigcommerce"
              >
                <StaticImage
                  src="../../../images/png/BigCommerce-logo-dark-sml.png"
                  alt="BigCommerce logo"
                  height={70}
                  className="ecommerce-logo"
                />
              </a>
            </div>
            <div className="flex mb-2 cursor-pointer">
              <a
                target="_blank"
                href="https://docs.apacerefunds.com/reference/woocommerce"
              >
                <StaticImage
                  src="../../../images/png/woocommerce-logo-color-black@2x.png"
                  alt="WooCommerce logo"
                  height={80}
                  className="ecommerce-logo"
                />
              </a>
            </div>
          </div>
          <div className="text-center">
            <hr />
            <StaticImage
              src="../../../images/png/shopify_logo_black.png"
              alt="Shopify logo"
              height={70}
              className="ecommerce-logo mt-[40px]"
            />
            <p className="italic text-[13px] text-muted mt-3">
              Custom & simple integration for Shopify users
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleIntegrations
