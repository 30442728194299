import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Insights from "./Insights"
import Chat from "./Chat"
const Testimonials = () => {
  return (
    <div className="w-full bg-[#E6EBF5] py-[40px]">
      <div className="pb-[100px] relative">
        <div className="container flex flex-col justify-center md:flex-row md:justify-between gap-8 my-[70px]">
          <div className="md:max-w-[280px]">
            <div className="flex justify-center ">
              <StaticImage
                src="../../../images/png/Group 278.png"
                alt="Apace"
                className="w-[95px] md:w-[120px] 2xl:w-auto"
              />
            </div>
            <h1 className=" text-lg  2xl:text-2xl font-medium text-center text-black">
              Joseph Walker
            </h1>
            <h2 className="text-lg  2xl:text-2xl font-normal italic text-lightgrey text-center">
              CEO, Chameleon Global
            </h2>
          </div>
          <div className="w-full md:max-w-[1100px]">
            <p className="text-lg md:text-[22px] leading-[38px] 2xl:text-[32px] text-center md:text-left text-black 2xl:leading-[44px] font-normal">
              <i>
                <span className="text-[60px] ml-[-20px]">“</span>We see many
                customers having to cancel / rebook their travel plans due to
                unforeseen circumstances. Customers spend large sums of money on
                their vacations and travel experiences so when they have to
                cancel, many customers have to wait until their refunds hit
                their account, which can prolong the rebooking, sometimes by two
                weeks or much more!
                <br />
                <b>
                  Apace has been a game-changing solution for our customers to
                  be able to get their money back or rebook their trips,
                  INSTANTLY..
                </b>
                <span className="text-[60px]" style={{ position: "absolute" }}>
                  ”
                </span>
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
