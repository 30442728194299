import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Stats from "./Stats"
import Header from "./Header"
import ContactUs from "./ContactUs"
import Affiliate from "./Affiliate"
import Partnership from "./Partnership"
import RevenueShare from "./RevenueShare"
import ApaceOffers from "./ApaceOffers"
import Testimonials from "./Testimonials"
import AdvancedRefund from "./AdvancedRefund"
import CustomerLoyalty from "./CustomerLoyalty"
import FromOurPipeline from "./FromOurPipeline"
import PipelineCarousel from "./PipelineCarousal"
import ApaceOffersMobile from "./ApaceOffersMobile"
import SimpleIntegrations from "./SimpleIntegrations"

import favicon from "../../../images/favicon.ico"
import Chat from "./Chat"

const Home = () => {
  const [chatOpen, setChatOpen] = useState(false)

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>
          Apace Refunds | The Smart Way to Keep Your Customers Happy and Boost
          Sales
        </title>
        <meta
          name="description"
          content="Apace Refunds For business | Apace offers instant refunds to customers, so they can buy with confidence. With Apace, you can bolster sales, build loyalty, and offer real-time payments."
        />
      </Helmet>
      <Header />
      <Chat chatOpen={chatOpen} setChatOpen={setChatOpen} />
      <Stats />
      <CustomerLoyalty />
      <div className="md:block hidden">
        <ApaceOffers />
      </div>
      <div className="md:hidden block">
        <ApaceOffersMobile />
      </div>
      <Partnership />
      <RevenueShare />
      <AdvancedRefund />
      <SimpleIntegrations />
      <Affiliate />

      <div className="md:block hidden">
        <FromOurPipeline />
      </div>
      <div className="md:hidden block">
        <PipelineCarousel />
      </div>
      <Testimonials />
      <ContactUs />
      {/* <div
        className="md:block hidden"
        style={{ position: "fixed", bottom: 20, right: 20 }}
      >
        <a
          href="https://www.producthunt.com/posts/apace-refunds?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-apace-refunds"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=370080&theme=neutral"
            alt="Apace Refunds - Get your instant refund in real-time | Product Hunt"
            style={{ width: 150, height: 33 }}
            width="250"
            height="54"
          />
        </a>
      </div> */}
    </>
  )
}

export default Home
