import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import image1 from "../../../images/leftMob.svg"
import image2 from "../../../images/rightmob.png"
import { HiChevronDown } from "react-icons/hi"

const ApaceOffers = () => {
  let option =
    typeof window !== "undefined" && window?.location.hash
      ? window?.location.hash === "#duly-refund"
        ? 2
        : 1
      : null
  const [openCollapese, setCollapese] = useState(1)

  const handleCollapse = id => {
    if (openCollapese === id) {
      setCollapese(!id)
    } else {
      setCollapese(id)
    }
  }

  useEffect(() => {
    if (option) {
      console.log("option", option)
      setCollapese(option)
    }
  }, [option])

  return (
    <div className="w-full bg-[#F5F8FD]" id="duly-refund">
      <div className="container py-[60px] md:py-[60px]">
        <div className="pb-[0px]">
          <h1 className=" text-2xl md:text-[32px] lg:text-[36px] lg:leading-[44px] 2xl:text-[42px] 2xl:leading-[56px] font-normal text-center text-black mb-[20px] md:mb-[30px] max-w-[900px] mx-auto">
            Apace offers your customers two efficient options for instant
            refunds
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="order-last md:order-first">
              <div className="relative" id="early-refund">
                {openCollapese === 2 ? (
                  <StaticImage
                    src="../../../images/fmob.svg"
                    alt="Apace"
                    className=" w-[70%] 2xl:w-[80%]"
                  />
                ) : (
                  <StaticImage
                    src="../../../images/mob_2.svg"
                    alt="Apace"
                    className=" w-[70%] 2xl:w-[80%]"
                  />
                )}
                <ZoomOutImage src={image1} alt="Option 1 at starting point" />
                <ZoomOutImage2 src={image2} alt="Option 2 at starting point" />
              </div>
            </div>
            <div className="flex flex-col self-center order-first md:order-last">
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleCollapse(item.id)}
                    className={`mb-[20px]  cursor-pointer  px-6 py-6 border-l-[12px]  ${
                      openCollapese === item.id
                        ? " transition-all duration-700 ease-in-out border-l-primary bg-white"
                        : " transition-all duration-700 ease-in-out border-l-transparent"
                    }  `}
                  >
                    <h1 className="text-sm text-[#878787] font-medium mb-3 2xl:mb-0s flex">
                      {item.option}{" "}
                      {openCollapese === item.id ? null : (
                        <HiChevronDown className="text-lg 2xl:text-2xl ml-[7px] mt-[-1px]" />
                      )}
                    </h1>
                    <h2 className=" text-xl md:text-[24px] 2xl:text-[32px] font-normal text-black mb-7">
                      {item.title}
                    </h2>
                    <p className="text-[13px] text-[#234d7d] font-normal mb-7 mt-[-18px]">
                      {item.subTitle}
                    </p>
                    <p className="text-[17px] text-[#515151] font-normal mb-10">
                      {item.description}
                    </p>
                    <div
                      className={`${
                        openCollapese === item.id ? "open" : "collapse"
                      } bg-transparent  `}
                    >
                      <h3 className="text-sm text-[#878787] uppercase font-medium mb-3">
                        {item.process}
                      </h3>
                      <h4 className="text-base font-normal text-black">
                        {item.processDecription}
                      </h4>
                      <div>
                        <ul className="progress">
                          {item.steps.map((step, index) => {
                            return (
                              <li
                                className="progress__item  progress__item--active"
                                key={index}
                              >
                                <div>
                                  <p className="progress__title">
                                    {step.title}
                                  </p>
                                  <p className="progress__info">
                                    {step.description}
                                  </p>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApaceOffers

const ZoomOutImage = ({ src, alt }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.img
      ref={ref}
      srcSet={src}
      loading="lazy"
      alt={alt}
      animate={controls}
      className="absolute top-[200px] md:top-[260px] left-0  2xl:-left-[70px] object-contain  shadow-2xl w-[180px] 2xl:w-[250px]"
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          type: "spring",
          scale: [0, 1.1, 0.95, 1.1, 1],
        },
        hidden: { opacity: 0 },
      }}
      transition={{ times: [0, 0.4, 0.5, 0.6, 0.75], duration: 1.6 }}
    />
  )
}
const ZoomOutImage2 = ({ src, alt }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.img
      ref={ref}
      srcSet={src}
      loading="lazy"
      alt={alt}
      animate={controls}
      className="absolute  bottom-[170px] right-48 2xl:right-20 object-contain shadow-2xl w-[160px] 2xl:w-[250px]"
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          type: "spring",
          scale: [0, 1.1, 0.95, 1.1, 1],
        },
        hidden: { opacity: 0 },
      }}
      transition={{
        delay: 0.4,
        times: [0, 0.4, 0.5, 0.6, 0.75],
        duration: 1.8,
      }}
    />
  )
}

const data = [
  {
    id: 1,
    tagId: "pre-acceptance",
    option: "Option 1",
    title: (
      <>
        <span style={{ color: "#234d7d" }}>
          <strong>Early</strong>
        </span>
        <span style={{ fontWeight: "lighter", color: "#0d83b9" }}>
          {" "}
          Instant Refund™
        </span>
      </>
    ),
    subTitle: "At time of drop shipping",
    description:
      "Description: This option allows the customer to receive the refund prior to the retailer's approval of the return request. The customer can receive the refund as soon as they ship the merchandise",
    process: "Our simple process",
    processDecription:
      "The customer receives a return label and tracking information",
    steps: [
      {
        num: 1,
        title: "Shares the tracking information with Apace",
        description:
          "By adding the tracking number in Apace or uploading a copy of the shipping label",
      },
      {
        num: 2,
        title: "Provides basic credit information to Apace",
        description: "Light credit check which does not affect credit score",
      },
      {
        num: 3,
        title: "Gets an instant refund in their bank",
        description: "Minor customer deduction",
      },
    ],
  },
  {
    id: 2,
    tagId: "post-acceptance",
    option: "Option 2",
    title: (
      <>
        <span style={{ color: "#234d7d" }}>
          <strong>Duly</strong>
        </span>
        <span style={{ fontWeight: "lighter", color: "#0d83b9" }}>
          {" "}
          Instant Refund™
        </span>
      </>
    ),
    subTitle: "At time of retailer's approval",
    description:
      "Description: This option allows for the customer to receive the refund once the retailer has approved the return request. The customer can expect the refund instantly rather than waiting 5-14 business days",
    process: "Our simple process",
    processDecription:
      "Customer gets a notification email from the retailer that they accepted the return",
    steps: [
      {
        num: 1,
        title: "Requests instant refund through Apace",
        description: "On the merchants website or via an email link",
      },
      {
        num: 2,
        title: "Provides basic information to Apace",
        description: "No credit check or screening needed",
      },
      {
        num: 3,
        title: "Gets an instant refund in their bank",
        description: "Minor customer deduction",
      },
    ],
  },
]
