import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const AdvancedRefund = () => {
  return (
    <div className="w-full" id="merchant-portal">
      <div className="container py-[100px] md:py-[110px]">
        <h1 className=" text-2xl leading-[32px] md:text-[30px] md:leading-[38px]  lg:text-[40px] lg:leading-[56px] text-center text-[#004990] font-normal italic mb-1">
          Advanced refund management system for merchants
        </h1>
        <StaticImage
          src="../../../images/png/desktop_refunds_01.png"
          alt="dashboard"
        />
      </div>
    </div>
  )
}

export default AdvancedRefund
