import React, { useState } from "react"
import { AiOutlineSend } from "react-icons/ai"
import Lottie from "react-lottie"
// import HubspotForm from "react-hubspot-form"
import success from "../../../assets/lottie/110915-mahajan-thankyou.json"

const ContactUs = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submited, setSubmited] = useState(false)
  const [showError, setShowError] = useState(false)
  const [reqiredFields, setReqiredFields] = useState({})

  const handleSubmit = () => {
    const firstName = document.getElementById("first_name").value
    const lastName = document.getElementById("last_name").value
    const company = document.getElementById("company").value
    const email = document.getElementById("email").value
    const url = document.getElementById("url").value
    const revenue = document.getElementById("revenue").value
    const industry = document.getElementById("industry").value
    const notes = document.getElementById("00N8V00000NHJ89").value

    if (
      !firstName ||
      !lastName ||
      !company ||
      !email ||
      !revenue ||
      !industry
    ) {
      setReqiredFields({
        firstName,
        lastName,
        company,
        email,
        revenue,
        industry,
      })
      return setShowError(true)
    }

    setSubmitting(true)

    fetch(
      `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00D8V000001oPtF&lead_source=Website&00N8V00000NGn2j=website_submitted&first_name=${firstName}&last_name=${lastName}&company=${company}&email=${email}&url=${url}&revenue=${revenue}&industry=${industry}&notes=${notes}`,
      {
        method: "POST",
        mode: "no-cors",
      }
    )
      .then(() => {
        setSubmited(true)
      })
      .catch(error => console.log("error", error))
  }

  const Submitted = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: success,
      rendererSettings: {
        preserveAspectRatio: "xMidYMin slice",
      },
    }
    return (
      <>
        <Lottie options={defaultOptions} height={200} width={200} />
        <h3 className="mb-2 text-2xl 2xl:text-[30px] text-[#0f4c82] 2xl:leading-[40px] font-normal text-center">
          Thanks for your submission!
        </h3>
        <p className="text-black italic text-[14px] font-medium text-center">
          An expert sales rep will be in touch with you soon
        </p>
      </>
    )
  }

  return (
    <div className="w-full bg-[#3D87F5] ">
      <div className="md:container md:mx-auto sm:w-full px-4  pt-[145px] pb-[100px] md:pb-[270px]">
        <div className="grid lg:grid-cols-3 gap-10">
          <div className="order-last lg:order-first">
            <h1 className=" text-[42px] 2xl:text-[64px] 2xl:leading-[87px] font-bold text-white md:pt-0">
              Let’s talk
            </h1>
            <h2 className=" text-2xl 2xl:text-[36px] 2xl:leading-[44px] max-w-[350px] font-medium text-white mb-[40px] md:mb-[70px]">
              Learn how Apace can benefit you
            </h2>
            <div className="mb-[30px]">
              <h1 className="text-sm text-[#E8E8E8B2] font-medium uppercase">
                Phone
              </h1>
              <h3 className=" text-2xl 2xl:text-[30px] text-white 2xl:leading-[40px] font-normal">
                1-888-718-1004
              </h3>
            </div>
            <div>
              <h1 className="text-sm text-[#E8E8E8B2] font-medium uppercase">
                Email
              </h1>
              <h3 className=" text-2xl 2xl:text-[30px] text-white 2xl:leading-[40px] font-normal">
                info@apacerefunds.com
              </h3>
            </div>

            <h3 className="text-white italic text-[18px] pt-2 font-medium mt-[40px]">
              Sign up for updates and notifications
            </h3>
            <div className="min-w-[85%] md:min-w-[70%] sm:min-w-[70%] flex items-center  border-b border[#978F8F] py-2 mb-[20px]">
              <input
                className="min-w-[92%] appearance-none font-medium bg-transparent placeholder:text-[#c4c4c4] border-none  text-white  py-1  leading-tight focus:outline-none"
                type="email"
                placeholder="Email*"
                aria-label="email"
              />
              <button className="text-white text-[18px] pt-2 font-medium flex">
                <AiOutlineSend className="ml-2 mt-[5px]" />
              </button>
            </div>
          </div>
          <div className="lg:col-span-2 relative order-first lg:order-last -mt-[300px] lg:mt-0">
            <div
              id="contact"
              className=" lg:absolute lg:-top-[200px]  lg:right-0"
            >
              <div className="w-full">
                <div className="w-full flex flex-col rounded-[30px] form_shadow lg:min-w-[670px]  lg:max-w-[670px] bg-white min-h-[500px] px-[30px] md:px-[40px] lg:px-[50px] xl:px-[64px] pt-[106px] pb-[93px]">
                  {submited ? (
                    <Submitted />
                  ) : (
                    <>
                      <h1 className="text-[32px] text-black italic font-normal mb-[28px]">
                        Get in touch with us
                      </h1>
                      <div className="flex items-center gap-4  py-2 mb-[38px]">
                        <div
                          className="border-b border[#978F8F] w-full"
                          style={{
                            borderColor:
                              showError && !reqiredFields.firstName
                                ? "red"
                                : null,
                          }}
                        >
                          <input
                            className="appearance-none text-sm font-medium bg-transparent placeholder:text-[#978F8F] border-none w-full text-[#978F8F]  py-1  leading-tight focus:outline-none "
                            id="first_name"
                            maxlength="40"
                            name="first_name"
                            placeholder="First name *"
                            aria-label="name"
                          />
                        </div>
                        <div
                          className="border-b border[#978F8F] w-full"
                          style={{
                            borderColor:
                              showError && !reqiredFields.lastName
                                ? "red"
                                : null,
                          }}
                        >
                          <input
                            className="appearance-none text-sm font-medium bg-transparent placeholder:text-[#978F8F] border-none w-full text-[#978F8F]  py-1  leading-tight focus:outline-none"
                            id="last_name"
                            maxlength="80"
                            name="last_name"
                            placeholder="Last name *"
                            aria-label="name"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-4  py-2 mb-[38px]">
                        <div
                          className="border-b border[#978F8F] w-full"
                          style={{
                            borderColor:
                              showError && !reqiredFields.email ? "red" : null,
                          }}
                        >
                          <input
                            className="appearance-none text-sm font-medium bg-transparent placeholder:text-[#978F8F] border-none w-full text-[#978F8F]  py-1  leading-tight focus:outline-none "
                            id="email"
                            maxlength="80"
                            name="email"
                            placeholder="Company email *"
                          />
                        </div>
                        <div
                          className="border-b border[#978F8F] w-full"
                          style={{
                            borderColor:
                              showError && !reqiredFields.company
                                ? "red"
                                : null,
                          }}
                        >
                          <input
                            className="appearance-none text-sm font-medium bg-transparent placeholder:text-[#978F8F] border-none w-full text-[#978F8F]  py-1  leading-tight focus:outline-none"
                            id="company"
                            maxlength="40"
                            name="company"
                            placeholder="Company *"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-4  py-2 mb-[38px]">
                        <div className="border-b border[#978F8F] w-full">
                          <input
                            className="appearance-none text-sm font-medium bg-transparent placeholder:text-[#978F8F] border-none w-full text-[#978F8F]  py-1  leading-tight focus:outline-none "
                            id="url"
                            maxlength="80"
                            name="url"
                            placeholder="Company website"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-4  py-2 mb-[38px]">
                        <div
                          className="border-b border[#978F8F] w-full"
                          style={{
                            borderColor:
                              showError && !reqiredFields.industry
                                ? "red"
                                : null,
                          }}
                        >
                          <select
                            id="industry"
                            name="industry"
                            className="w-full"
                            style={{ outline: "none" }}
                          >
                            <option value="">Select Industry</option>
                            <option value="Agriculture">Agriculture</option>
                            <option value="Apparel">Apparel</option>
                            <option value="Banking">Banking</option>
                            <option value="Biotechnology">Biotechnology</option>
                            <option value="Chemicals">Chemicals</option>
                            <option value="Communications">
                              Communications
                            </option>
                            <option value="Construction">Construction</option>
                            <option value="Consulting">Consulting</option>
                            <option value="Education">Education</option>
                            <option value="Electronics">Electronics</option>
                            <option value="Energy">Energy</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Entertainment">Entertainment</option>
                            <option value="Environmental">Environmental</option>
                            <option value="Finance">Finance</option>
                            <option value="Food &amp; Beverage">
                              Food &amp; Beverage
                            </option>
                            <option value="Government">Government</option>
                            <option value="Healthcare">Healthcare</option>
                            <option value="Hospitality">Hospitality</option>
                            <option value="Insurance">Insurance</option>
                            <option value="Machinery">Machinery</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Media">Media</option>
                            <option value="Not For Profit">
                              Not For Profit
                            </option>
                            <option value="Other">Other</option>
                            <option value="Recreation">Recreation</option>
                            <option value="Retail">Retail</option>
                            <option value="Shipping">Shipping</option>
                            <option value="Technology">Technology</option>
                            <option value="Telecommunications">
                              Telecommunications
                            </option>
                            <option value="Transportation">
                              Transportation
                            </option>
                            <option value="Utilities">Utilities</option>
                          </select>
                        </div>
                        <div
                          className="border-b border[#978F8F] w-full"
                          style={{
                            borderColor:
                              showError && !reqiredFields.revenue
                                ? "red"
                                : null,
                          }}
                        >
                          <select
                            id="revenue"
                            name="revenue"
                            className="w-full"
                            style={{ outline: "none" }}
                          >
                            <option value="">Select Annual Revenue</option>

                            <option value="Pre-Launch">Pre-Launch</option>
                            <option value="< 1M">&lt; 1M</option>
                            <option value="1M-3M">1M-3M</option>
                            <option value="3M-10M">3M-10M</option>
                            <option value="10M-20M">10M-20M</option>
                            <option value="20M-100M >">20M-100M &gt;</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex items-center gap-4  py-2 mb-[38px]">
                        <div className="border-b border[#978F8F] w-full">
                          <textarea
                            className="appearance-none text-sm font-medium bg-transparent placeholder:text-[#978F8F] border-none w-full text-[#978F8F]  py-1  leading-tight focus:outline-none "
                            id="00N8V00000NHJ89"
                            name="00N8V00000NHJ89"
                            placeholder="Details"
                          ></textarea>
                        </div>
                      </div>
                      <div className="flex justify-end ">
                        <button
                          disabled={submitting}
                          onClick={handleSubmit}
                          className="bg-[#3D87F5] flex justify-center w-full items-center text-white text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl max-w-[206px] min-h-[60px]"
                        >
                          {submitting ? "Submitting ..." : "Submit"}
                        </button>
                      </div>
                      {/* <HubspotForm
                    portalId="21879558"
                    formId="b13c66c5-2481-4119-b56c-e0b5e936582c"
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  /> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
