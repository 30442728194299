import React, { useState } from "react"
import HandshakeImage from "../../../images/affiliate-handshake.svg"

const Affiliate = () => {
  const [openCollapese, setOpenCollapese] = useState(false)

  return (
    <div id="marketplace" className="pt-[200px] pb-[50px]">
      <div
        className="max-w-[1440px] container xl:rounded-[20px] py-[60px] px-[60px] text-white"
        style={{
          background:
            "linear-gradient(120deg, rgba(76,84,164,1) 0%, rgba(62,105,181,1) 50%, rgba(0,73,144,1) 100%)",
          position: "relative",
          "box-sizing": "border-box",
        }}
      >
        <div
          className="hidden md:block"
          style={{
            position: "absolute",
            top: "-200px",
            right: "70px",
            width: "35%",
          }}
        >
          <img src={HandshakeImage} alt="Affiliate opportunities with Apace" />
        </div>
        <div
          className="block md:hidden"
          style={{
            position: "relative",
            top: "-50px",
            "margin-left": "25%",
            width: "50%",
          }}
        >
          <img src={HandshakeImage} alt="Affiliate opportunities with Apace" />
        </div>
        <div className="ml-[40px]">
          <h1 className="text-2xl md:text-[32px] lg:text-[36px] lg:leading-[44px] 2xl:text-[42px] font-normal text-white">
            <span>
              Marketplace.
              <br />
              Partner with the Leader in Instant-Refunds.
            </span>
          </h1>
          <p className="mt-[20px] pr- font-regular text-white md:text-[18px] md:pr-[45%]">
            Work with Apace to implement the Most Innovative Solution in
            eCommerce Payments. Empower all of your Customers to receive Early
            or Instant Refunds on all eligible returns.
          </p>
          <button
            onClick={() => setOpenCollapese(!openCollapese)}
            className="mt-[30px] bg-[#ffffff] inline-flex justify-center items-center text-[#004990] text-base md:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl px-[20px] md:px-[30px] min-h-[50px]"
          >
            Become a Marketplace Partner
          </button>
          <div
            className={`${
              openCollapese ? "open" : "collapse"
            } bg-transparent  `}
          >
            <h3 className="mt-[60px] text-[25px] lg:leading-[36px] 2xl:text-[28px] font-normal text-white">
              Find the solution that best fits your Partnership needs.
            </h3>
            <p className="mt-[20px] pr-font-regular text-white md:text-[16px] md:pr-[45%]">
              <strong>Referral Partnership: </strong> Referral arrangements are
              designed so you can empower your network with the Apace Refunds
              solution. Know a retailer who would benefit from 24% Increased
              Revenue or 30% Cash Flow Improvement? This Partnership is the best
              fit for you.
            </p>
            <p className="mt-[20px] pr-font-regular text-white md:text-[16px] md:pr-[45%]">
              <strong>Marketplace Solution: </strong> Marketplace relationships
              are formed to create a seamless solution for your greater vendor
              ecosystem. This can range from a "Technology Marketplace Listing"
              to a "Feature Co-Marketing Relationship".
            </p>
            <a
              href="https://calendly.com/apacerefunds-yitz/15-minute-meeting"
              target={"_blank"}
              className="text-[9px] md:text-[16px] mt-[30px] bg-[#ffffff] inline-flex justify-center items-center text-[#004990] text-base font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl px-[13px] md:px-[30px] min-h-[40px]"
            >
              Connect with our Head of Channel Partnerships to Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Affiliate
