import React from "react"
import { useInView } from "react-intersection-observer"
import CountUp from "react-countup"
const Insights = () => {
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  return (
    <>
      <div className="px-[40px] py-[17px] my-[30px]">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 border-r insight_cards">
          {data.map((item, index) => {
            return (
              <div key={index} ref={ref}>
                <div className="flex justify-center">
                  {inView ? (
                    <div className="flex justify-center">
                      <CountUp
                        duration={2}
                        start={0}
                        end={item.totalNumber}
                        className=" text-[28px] md:text-[40px] xl:text-[50px] 2xl:text-[64px] 2xl:leading-[87px] text-center font-medium text-white"
                      />
                      <span className=" text-[28px] md:text-[40px] xl:text-[50px] 2xl:text-[64px] 2xl:leading-[87px] text-center font-medium text-white">
                        {"%"}
                      </span>
                    </div>
                  ) : (
                    <span className="text-[28px] md:text-[40px] xl:text-[50px] 2xl:text-[64px] 2xl:leading-[87px] text-center font-medium text-white">
                      {"0%"}
                    </span>
                  )}
                </div>
                <h2 className=" text-[14px] md:text-[16px] lg:text-[18px] 2xl:text-[24px] font-normal text-center italic text-white">
                  {item.title}
                  {item.hasStar ? (
                    <span className="text-[16px]"> *</span>
                  ) : null}
                </h2>
                <h2 className=" text-xs md:text-base italic text-center font-normal text-[#dbe6ff]">
                  {item.description}
                </h2>
              </div>
            )
          })}
        </div>
      </div>
      <span className="source-company text-white">
        * Source: The Strawhecker Group, LLC
      </span>
    </>
  )
}

export default Insights

const data = [
  {
    hasStar: true,
    totalNumber: 73,
    title: "increased customer loyalty",
    description: "(instant refunds enhance repurchase intentions)",
  },
  {
    hasStar: true,
    totalNumber: 84,
    title: "customer satisfaction",
    description: "(when given the option for instant refunds)",
  },
  {
    totalNumber: 24,
    title: "increased revenue",
    description: "(bigger purchases and faster repeat customers)",
  },
  {
    totalNumber: 30,
    title: "retailer cash flow improvement",
    description: "(extended refund period)",
  },
]
