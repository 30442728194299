import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const CustomerLoyalty = () => {
  return (
    <div className="w-full bg-white">
      <div className="container py-[120px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 2xl:gap-4">
          <div className="mb-10 md:mb-0">
            <StaticImage
              className="loyelty-image"
              src="../../../images/Rectangle 205.svg"
              alt="A dinosaur"
            />
          </div>
          <div className="flex self-center">
            <div>
              <h1 className=" text-2xl leading-[32px] md:text-[28px] md:leading-[34px] lg:text-[30px] lg:leading-[44px] 2xl:text-[40px] 2xl:leading-[54px] font-normal  text-black mb-[40px]">
                Embolden your customers to buy with confidence by partnering
                with Apace
              </h1>
              <p className=" text-lg leading-[30px] md:text-[20px] lg:text-[22px] font-normal text-dark lg:leading-[34px] mb-[40px]">
                When your customers return merchandise, they receive their
                instant refund payment in their bank account with a minor
                deduction.
                <br />
                You are later invoiced for the refund with{" "}
                <span className="text-dark font-bold">no additional charge</span>.
              </p>
              <div className="p-[17px] mb-[50px] 2xl:mr-[145px] md:mr-[40px] rrp-div">
                <p className="text-base leading-[24px] md:text-[19px] md:leading-[25px] 2xl:text-[19px] 2xl:leading-[28px] text-lightgrey ">
                  See our{" "}
                  <a href="#reimbursement" className="font-bold text-[#007eff]">
                    Return Reimbursement Program
                  </a>{" "}
                  to replenish costs associated with the return process
                </p>
              </div>
              <div>
                <a
                  href="https://merchant.apacerefunds.com/auth/signup"
                  className="bg-primary flex justify-center items-center text-white text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl max-w-[180px] min-h-[54px]"
                >
                  Sign up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerLoyalty
