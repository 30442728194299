import React, { useState } from "react"
import Layout from "../components/Layout"
import Home from "../components/Views/Home"
import LogRocket from "logrocket"
import HeroHeader from "../components/HeroHeader/HeroHeader"

LogRocket.init("hrmozg/website-for-retailers")

const isBrowser = typeof window !== "undefined"
const HomePage = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  if (isBrowser) {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }
  return (
    <div>
      <Layout isScrolled={isScrolled}>
        <Home />
      </Layout>
    </div>
  )
}

export default HomePage
