import React from "react"
import Insights from "./Insights"

const Stats = () => {
  return (
    <div className="container p-0 relative">
      <div className="left-4 right-4 -top-20 md:-top-10">
        <div className="w-full bg-white rounded-[20px] insight_shadow mt-[-60px] bg-[#15497C]">
          <Insights />
        </div>
      </div>
    </div>
  )
}

export default Stats
