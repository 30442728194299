import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { StaticImage } from "gatsby-plugin-image"
import Typing from "react-typing-animation"
import Animate from "react-reveal/Rotate"
import Linkify from "react-linkify"
import axios from "axios"

const CHATBOT_API = "https://chatbot.api.apacerefunds.com"
// const CHATBOT_API = "http://localhost:3000"

const Chat = ({ chatOpen, setChatOpen }) => {
  const [chats, setChats] = useState([])
  const [chatId, setChatId] = useState("")
  const [question, setQuestion] = useState("")
  const [chatHasError, setChatHasError] = useState(false)
  const [chatHasAnsrError, setChatHasAnsrError] = useState(false)
  const [disableQuestion, setDisableQuestion] = useState(false)
  const [preQuestions, setPreQuestions] = useState([])
  const [typingDone, setTypingDone] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const handleNewChat = async () => {
    try {
      let res = await axios({
        method: "post",
        url: `${CHATBOT_API}/chat/new`,
        data: {},
      })
      if (res.data.conversation) {
        setChatId(res.data._id)
        setChats(res.data.conversation)
        setChatOpen(true)
        setTimeout(() => setChatOpen(false), 5000)
      }
    } catch (error) {
      setChatHasError(true)
    }
  }

  const handleQuestion = ({ target }) => {
    setQuestion(target.value)
  }

  const formatQuestion = (text, user) => {
    return { user: user, datetime: new Date(), text: text }
  }

  const scrollChatToBottom = () => {
    document
      .getElementById("chat")
      ?.scrollTo({ behavior: "smooth", top: 1000000 })
  }

  const handleAskQuestion = async qu => {
    if (!qu) return
    setDisableQuestion(true)
    setTypingDone(false)
    //@ts-ignore
    setChats([...chats, formatQuestion(qu, "user")])
    setTimeout(scrollChatToBottom, 500)

    try {
      let res = await axios({
        method: "post",
        url: `${CHATBOT_API}/chat/${chatId}`,
        data: { message: qu },
      })
      if (res.data.conversation) setChats(res.data.conversation)
      setQuestion("")
      setChatHasAnsrError(false)
      scrollChatToBottom()
    } catch (error) {
      setChatHasAnsrError(true)
    }
    setDisableQuestion(false)
  }

  const handleGetPreQuestions = async () => {
    try {
      let res = await axios({
        method: "get",
        url: `${CHATBOT_API}/chat/${chatId}/pre-populated-qu`,
      })
      if (res.data.questions) {
        setPreQuestions(res.data.questions)
        scrollChatToBottom()
      }
    } catch (error) {}
  }

  const handlePreQuestionClick = qu => {
    setPreQuestions([])
    handleAskQuestion(qu)
  }

  useEffect(() => {
    setTimeout(handleNewChat, 3000)
  }, [])

  const PreQuestions = () => {
    if (!preQuestions.length) return null
    return (
      <div className="pre-questions-div">
        {preQuestions.map(q => (
          <button
            className="pre-question"
            onClick={() => handlePreQuestionClick(q)}
          >
            {q}
          </button>
        ))}
      </div>
    )
  }

  const BotHeader = () => (
    <div className="bot-header">
      <StaticImage
        alt="bot-icon"
        src="../../../images/png/chatbot-icon.png"
        className={`chatbot-icon`}
      />
      <div>
        <h2>John AI</h2>
        <p>An Apace smart AI salesman here to help you. Wanna chat?</p>
      </div>
      <p className="close-chat" onClick={() => setChatOpen(false)}>
        X
      </p>
    </div>
  )

  return (
    <>
      {chatHasError ? null : (
        <div ref={ref} className="container py-[26px] chat-parant-cont">
          <button
            className="chat-button"
            onClick={() => setChatOpen(!chatOpen)}
          >
            <StaticImage
              alt="bot"
              src="../../../images/png/chatbot-icon.png"
              className={`chatbot-icon ${chatOpen ? "open" : ""}`}
            />
          </button>
          <Animate right when={chatOpen}>
            <div id="chat" className="chat-container">
              <div className="chat-header">
                <BotHeader />
              </div>

              <div className="chats">
                {chats.length ? (
                  chats.map((chat, i) => (
                    <>
                      {/* @ts-ignore */}
                      {chat.user == "apace_bot" ? (
                        <div className="chat-bubble apace">
                          <StaticImage
                            src="../../../images/png/apace_icon.png"
                            alt="Apace Bot"
                            style={{ overflow: "initial" }}
                          />
                          <p className="chat-single show chat-apace">
                            {chats.length == i + 1 && !typingDone ? (
                              <Typing
                                speed={20}
                                onFinishedTyping={() => {
                                  scrollChatToBottom()
                                  setTypingDone(true)
                                  !i && handleGetPreQuestions()
                                }}
                              >
                                {/* @ts-ignore */}
                                {chat.text}
                              </Typing>
                            ) : (
                              <>
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {/* @ts-ignore */}
                                  {chat.text}
                                </Linkify>
                              </>
                            )}
                            <p className="chat-user-time">
                              John AI - {/* @ts-ignore */}
                              {new Date(chat.datetime).toLocaleTimeString()}
                            </p>
                          </p>
                        </div>
                      ) : (
                        <div className="chat-bubble user">
                          {/* @ts-ignore */}
                          <p className="chat-single show chat-user">
                            {/* @ts-ignore */}
                            {chat.text}
                            <p className="chat-user-time">
                              You - {/* @ts-ignore */}
                              {new Date(chat.datetime).toLocaleTimeString()}
                            </p>
                          </p>
                        </div>
                      )}
                    </>
                  ))
                ) : (
                  <div className="chat-bubble apace">
                    <StaticImage
                      src="../../../images/png/apace_icon.png"
                      alt="Apace Bot"
                      style={{ overflow: "initial" }}
                    />
                    <p className="chat-single show chat-apace">
                      <Typing speed={200} loop={true}>
                        .{"   "}
                      </Typing>
                    </p>
                  </div>
                )}
                <div className="chat-input">
                  <input
                    id="questionInput"
                    value={question}
                    onChange={handleQuestion}
                    onKeyUp={({ key }) =>
                      key == "Enter" && handleAskQuestion(question)
                    }
                    disabled={disableQuestion}
                    placeholder="Type a message..."
                    ref={input => input && input.focus()}
                    autoComplete="off"
                  />
                  <button
                    onClick={() => handleAskQuestion(question)}
                    title="submit"
                  >
                    <StaticImage
                      src="../../../images/png/enter.png"
                      alt="Send"
                    />
                  </button>
                </div>
                {chatHasAnsrError ? (
                  <p className="text-[red]">
                    Sorry there was an error answering your question, please try
                    again
                  </p>
                ) : null}
                <PreQuestions />
              </div>
            </div>
          </Animate>
        </div>
      )}
    </>
  )
}

export default Chat
