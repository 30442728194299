import React from "react"
import Slider from "react-slick"
import crypto from "../../../images/001-currency-exchange.svg"
import clock from "../../../images/002-clock.svg"
import credit from "../../../images/003-credit-card.svg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const PipelineCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className="w-full " id="roadmap">
      <div className="container pt-[80px] pb-[240px] md:pb-[160px]">
        <h1 className=" text-2xl md:text-[36px] 2xl:text-[42px] text-center md:text-left italic text-black font-normal mb-[80px] md:mb-[120px]">
          From the Apace roadmap
        </h1>
        <Slider {...settings}>
          {data.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex justify-center md:justify-start">
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="w-[56px] h-[56px] mb-[26px]"
                  />
                </div>
                <h1 className=" text-[28px] 2xl:text-[32px] italic font-normal text-black mb-[5px] text-center md:text-left">
                  {item.title}
                </h1>
                <h6 className="text-sm text-lightgrey font-medium mb-[20px] text-center md:text-left">
                  {item.date}
                </h6>
                <p className=" text-base 2xl:text-lg italic text-dark font-normal md:max-w-[390px] text-center md:text-left">
                  {item.description}
                </p>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default PipelineCarousel
const data = [
  {
    icon: credit,
    title: "Apace Debit™",
    date: "Q4-2022",
    description:
      "The Apace Debit™ card will allow customers to keep track of all refunds in one place and will provide accessible funds for purchases.",
  },
  {
    icon: crypto,
    title: "Apace Crypto™",
    date: "Q3-2023",
    description:
      "With Apace Crypto™ the customer will have the option to get an instant refund in their preferred crypto currency",
  },
  {
    icon: clock,
    title: "Apace In-store Refund™",
    date: "Q4-2023",
    description:
      "For customers shopping and returning in-store, the Apace In-store Refund™ will issue the refund in-store.",
  },
]
