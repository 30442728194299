import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import image1 from "../../../images/leftMob.svg"
import image2 from "../../../images/rightmob.png"
const ApaceOffersMobile = () => {
  const [openCollapese, setCollapese] = useState(1)
  const handleCollapse = id => {
    if (openCollapese === id) {
      setCollapese(!id)
    } else {
      setCollapese(id)
    }
  }

  return (
    <div className="w-full bg-[#F5F8FD]">
      <div className="w-full py-[60px] md:py-[120px] px-7">
        <div className="pb-[0px] md:pb-[120px]">
          <h1 className=" text-2xl md:text-[32px] lg:text-[36px] lg:leading-[44px] 2xl:text-[42px] 2xl:leading-[56px] font-normal text-center text-black mb-[20px] md:mb-[30px] max-w-[900px] mx-auto">
            Apace offers your customers two efficient options for instant
            refunds
          </h1>

          <div className="grid grid-cols">
            <div className="flex flex-col self-center">
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    id={item.specialId}
                    onClick={() => handleCollapse(item.id)}
                    className={`mb-[20px]  cursor-pointer  px-1 py-1`}
                  >
                    <h1
                      id={item.tagId}
                      className="text-sm text-[#878787] font-medium mb-3 2xl:mb-0s "
                    >
                      {item.option}
                    </h1>
                    <h2 className="text-[25px] 2xl:text-[32px] font-normal text-black mb-7">
                      {item.title}
                    </h2>
                    <p className="text-[13px] text-[#234d7d] font-normal mb-7 mt-[-18px]">
                      {item.subTitle}
                    </p>
                    <p className="text-[17px] text-[#515151] font-normal mb-10">
                      {item.description}
                    </p>
                    <div className={`bg-transparen open`}>
                      <h3 className="text-sm text-[#878787] uppercase font-medium mb-3">
                        {item.process}
                      </h3>
                      <h4 className="text-base font-normal text-black">
                        {item.processDecription}
                      </h4>
                      <div>
                        <ul className="progress">
                          {item.steps.map((step, index) => {
                            return (
                              <li
                                className="progress__item  progress__item--active"
                                key={index}
                              >
                                <div>
                                  <p className="progress__title">
                                    {step.title}
                                  </p>
                                  <p className="progress__info">
                                    {step.description}
                                  </p>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="relative">
                        {item.id === 2 ? (
                          <StaticImage
                            src="../../../images/fmob.svg"
                            alt="apace"
                            className=" w-[95%] 2xl:w-auto"
                          />
                        ) : (
                          <StaticImage
                            src="../../../images/mob_2.svg"
                            alt="apace"
                            className=" w-[95%] 2xl:w-auto"
                          />
                        )}
                        <ZoomOutImage
                          src={image1}
                          alt="Option 1 at starting point"
                        />
                        <ZoomOutImage2
                          src={image2}
                          alt="Option 2 at starting point"
                        />
                      </div>
                    </div>
                    {index === 0 ? (
                      <hr className="border-b border[#D4D4D4]" />
                    ) : (
                      ""
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApaceOffersMobile

const ZoomOutImage = ({ src, alt }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.img
      ref={ref}
      srcSet={src}
      loading="lazy"
      alt={alt}
      animate={controls}
      className="absolute top-[200px] md:top-[260px] left-0  2xl:-left-[100px] object-contain  shadow-2xl w-[180px] 2xl:w-auto"
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          type: "spring",
          scale: [0, 1.1, 0.95, 1.1, 1],
        },
        hidden: { opacity: 0 },
      }}
      transition={{ times: [0, 0.4, 0.5, 0.6, 0.75], duration: 1.6 }}
    />
  )
}
const ZoomOutImage2 = ({ src, alt }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.img
      ref={ref}
      srcSet={src}
      loading="lazy"
      alt={alt}
      animate={controls}
      className="absolute  bottom-[150px]  right-0 object-contain shadow-2xl w-[160px] 2xl:w-auto"
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          type: "spring",
          scale: [0, 1.1, 0.95, 1.1, 1],
        },
        hidden: { opacity: 0 },
      }}
      transition={{
        delay: 0.4,
        times: [0, 0.4, 0.5, 0.6, 0.75],
        duration: 1.8,
      }}
    />
  )
}

const data = [
  {
    id: 1,
    tagId: "early-refund2",
    option: "Option 1",
    title: (
      <>
        <span style={{ color: "#234d7d" }}>
          <strong>Early</strong>
        </span>
        <span style={{ fontWeight: "lighter", color: "#0d83b9" }}>
          {" "}
          Instant Refund™
        </span>
      </>
    ),
    subTitle: "At time of drop shipping",
    description:
      "Description: This option allows the customer to receive the refund prior to the retailer's approval of the return request. The customer can receive the refund as soon as they ship the merchandise",
    process: "Our simple process",
    processDecription:
      "The customer receives a return label and tracking information",
    steps: [
      {
        num: 1,
        title: "Shares the tracking information with Apace",
        description:
          "By adding the tracking number in Apace or uploading a copy of the shipping label",
      },
      {
        num: 2,
        title: "Provides basic credit information to Apace",
        description: "Light credit check which does not affect credit score",
      },
      {
        num: 3,
        title: "Gets an instant refund in their bank",
        description: "Minor customer deduction",
      },
    ],
  },
  {
    id: 2,
    tagId: "duly-refund2",
    option: "Option 2",
    title: (
      <>
        <span style={{ color: "#234d7d" }}>
          <strong>Duly</strong>
        </span>
        <span style={{ fontWeight: "lighter", color: "#0d83b9" }}>
          {" "}
          Instant Refund™
        </span>
      </>
    ),
    subTitle: "At time of retailer's approval",
    description:
      "Description: This option allows for the customer to receive the refund once the retailer has approved the return request. The customer can expect the refund instantly rather than waiting 5-14 business days",
    process: "Our simple process",
    processDecription:
      "Customer gets a notification email from the retailer that they accepted the return",
    steps: [
      {
        num: 1,
        title: "Requests instant refund through Apace",
        description: "On the merchants website or via an email link",
      },
      {
        num: 2,
        title: "Provides basic information to Apace",
        description: "No credit check or screening needed",
      },
      {
        num: 3,
        title: "Gets an instant refund in their bank",
        description: "Minor customer deduction",
      },
    ],
  },
]
